<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal(null)"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد شهر
        </b-button>
      </b-col>
    </b-row>
    <div>
      <div>
        <b-row>
          <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" />

          <b-col sm="12" md="8">
            <b-input-group>
              <b-form-input

                v-model="searchInput"
                placeholder="برای جستجو نام شهر را وارد کنید"
              />

            </b-input-group>
          </b-col>
          <b-col>
            <!-- <b-button variant="primary" block @click="searchBase()">
            <span v-if="submitLoading">
              <b-spinner small class="p-0 m-0" />
              <span class="sr-only">Loading...</span>
            </span>

            <div v-else class="text-capitalize">جستجو</div>
          </b-button> -->
          </b-col>
        </b-row>
      </div>

      <b-card body-class="p-0" class="mt-1">
        <table-head
          ref="citiesTableHeadComponent"
          :items="tableHeadings"
          @sort="sort"
        />
        <b-row
          class="py-1 mx-1 border-bottom"
          v-for="city in cities"
          :key="city.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            {{ city.name }}
          </b-col>
          <b-col class="d-flex align-items-center">
            <div v-for="(sate, i) in provinces">
              <div v-if="sate.id === city.state.name">
                {{ sate.name }}
              </div>
            </div>
          </b-col>

          <b-col
            class="d-flex align-items-center flex-row-reverse"
            style="direction: ltr"
          >
            {{
              new Date(city.created_at).toLocaleString("fa-IR", {
                timeZone: "Iran",
              })
            }}
          </b-col>
          <b-col class="d-flex align-items-center">
            <router-link
                :to="{ name: 'citiesStatistics', params: { id: city.id,name:city.name } }"
                variant="flat-secondary"
                class="font-weight-bolder btn"
            >
              <BIconCalendar />
            </router-link>
            <b-button
              @click="showConfirmModal(city)"
              variant="flat-danger"
              class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
              @click="showFormModal(city)"
              variant="flat-secondary"
              class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination
        ref="citiesPaginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
    <b-sidebar
      width="98%"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
      @shown="sliderShown"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش شهر" : "ایجاد شهر" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="row">
        <b-form-group class="mt-1 col-md-6">
          <label for="city_province" class="text-capitalize"> استان :</label>
          <v-select
            id="city_province"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="cityInput.state_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :reduce="(province) => province.id"
            :options="provinces"
            @search="getCities"
            placeholder="برای جستجو تایپ کنید"
          />
        </b-form-group>

        <b-form-group class="mt-1 col-md-6">
          <label for="name" class="text-capitalize"> نام : </label>
          <b-form-input id="name" v-model="cityInput.name" type="text" />
        </b-form-group>
        <b-form-group class="mt-1 col-md-6">
          <label for="city_slug" class="text-capitalize">اسلاگ| slug:</label>
          <b-form-input id="city_slug" v-model="cityInput.slug" type="text" />
        </b-form-group>
        <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
          <label for="province_status" class="text-capitalize"> وضعیت : </label>
          <b-form-radio
            v-model="cityInput.status"
            :aria-describedby="ariaDescribedby"
            value="active"
            >فعال</b-form-radio
          >
          <b-form-radio
            v-model="cityInput.status"
            :aria-describedby="ariaDescribedby"
            value="inactive"
            >پیش نویس</b-form-radio
          >
        </b-form-group>



        <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby1 }">
          <label for="province_primary_city" class="text-capitalize"> اولویت شهرهای روم تور : </label>
          <b-form-radio
              v-model="cityInput.primary_city"
              value="active"
              :aria-describedby="ariaDescribedby1"
          >
            اولویت دار
          </b-form-radio
          >
          <b-form-radio
              v-model="cityInput.primary_city"
              :aria-describedby="ariaDescribedby1"
              value="inactive"
          >بدون اولویت</b-form-radio
          >
        </b-form-group>


        <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby1 }">
          <label for="order_city" class="text-capitalize"> شماره اولویت شهرهای روم تور : </label>
          <b-form-input id="order_city" v-model="cityInput.order_city" type="text" />
        </b-form-group>


        <b-form-group class="mt-1 col-md-6">
          <label for="city_banner" class="text-capitalize"
            >آپلود بنر (600*1500):</label
          >
          <!-- <b-form-input
            id="city_banner"
            v-model="cityInput.banner"
            type="text"
          /> -->
          <FilePicker
            ref="bannerFilePicker"
            @inputFile="inputFile"
            :url="imageUrl"
          ></FilePicker>
        </b-form-group>
        <b-form-group class="mt-1 col-md-12">
          <label for="city_description" class="text-capitalize">توضیحات:</label>
          <vue-editor
            v-model="cityInput.description"
            :editorToolbar="customToolbar"
          ></vue-editor>
        </b-form-group>

        <b-form-group class="mt-1 col-md-12">
          <label for="city_images" class="text-capitalize"
          >تصاویر شهر (از طریق سرور دانلود لینک دانلود خوانده و با کاما, انگلیسی جدا سازی شود ,)</label
          >
          <b-form-textarea
              style="direction: ltr"
              rows="4"
              id="city_images"
              v-model="cityInput.images"
              type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1 col-md-12">
          <label for="city_videos" class="text-capitalize"
          >ویدیو های شهر (از طریق سرور دانلود لینک دانلود خوانده و با کاما, انگلیسی جدا سازی شود ,)</label
          >
          <b-form-textarea
              style="direction: ltr"
              rows="4"
              id="city_videos"
              v-model="cityInput.videos"
              type="text"
          />
        </b-form-group>

        <b-form-group class="mt-1 col-md-12">
          <label for="city_videos" class="text-capitalize"
          >پادکست های شهر (از طریق سرور دانلود لینک دانلود خوانده و با کاما, انگلیسی جدا سازی شود ,)</label
          >
          <b-form-textarea
              style="direction: ltr"
              rows="4"
              id="city_videos"
              v-model="cityInput.podcasts"
              type="text"
          />
        </b-form-group>

        <b-form-group class="mt-1 col-md-12">
          <label for="city_videos" class="text-capitalize"
          >سوالات متداول شهر (انتهای سوال علامت ؟ و در انتهای پاسخ کام , قرار دهید)</label
          >
          <b-form-textarea
              rows="4"
              id="city_videos"
              v-model="cityInput.faqs"
              type="text"
          />
        </b-form-group>



      </b-container>
      <b-container class="row">

          <b-col md="6">
            <b-form-group class="mt-1 col-md-12">
              <label for="city_banner" class="text-capitalize"
              >طول جغرافیایی (lng):</label
              >
              <b-form-input
                  id="city_banner"
                  v-model="cityInput.lng"
                  type="text"
              />
            </b-form-group>
            <b-form-group class="mt-1 col-md-12">
              <label for="city_banner" class="text-capitalize"
                >عرض جغرافیایی (lat):</label
              >
              <b-form-input
                id="city_banner"
                v-model="cityInput.lat"
                type="text"
              />
            </b-form-group>

            <b-button type="button" @click="searchLatLng()">جستجو</b-button>
          </b-col>
          <b-col md="6">
            <div  style="position: relative;">
              <l-map
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 200px"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
                ref="mymap"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
              </l-map>

              <feather-icon
                icon="MapPinIcon"
                size="20"
                fill="#FFF "
                style="
                  position: absolute;
                  top: 50%;
                  margin-top: -20px;
                  z-index: 9999;
                  left: 50%;
                  margin-left: -10px;
                "
              />
            </div>
          </b-col>
      </b-container>
      <hr />

      <b-container class="row">
        <div class="sidebarHeaderTitleDiv col-md-12 mr-1">
          <h3 class="text-capitalize">تنظیمات سئو</h3>
        </div>
        <b-form-group class="mt-1 col-md-12">
          <label for="city_seo_data_title" class="text-capitalize">
            عنوان SEO :</label
          >
          <b-form-input
            id="name"
            v-model="cityInput.seo_data_title"
            type="text"
          />
        </b-form-group>

        <b-form-group class="mt-1 col-md-12">
          <label for="seo_data_keywords" class="text-capitalize">
            کلمات کلیدی (با "،" جدا شود) :
          </label>
          <b-form-textarea
            rows="1"
            id="seo_data_keywords"
            v-model="cityInput.seo_data_keywords"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1 col-md-12">
          <label for="seo_data_description" class="text-capitalize"
            >توضیجات SEO:</label
          >
          <b-form-textarea
            rows="4"
            id="seo_data_description"
            v-model="cityInput.seo_data_description"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1 col-md-12">
          <label for="seo_data_schema" class="text-capitalize"
            >schemaa SEO:</label
          >
          <b-form-textarea
            rows="4"
            id="seo_data_schema"
            v-model="cityInput.seo_data_schema"
            type="text"
          />
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend, BIconCalendar,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import { VueEditor } from "vue2-editor";
import L from "leaflet";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import { Icon } from "leaflet";
import FilePicker from "@/components/FilePicker.vue";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BIconCalendar,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    VueEditor,
    BFormRadio,
    FilePicker,
    BInputGroup,
  BInputGroupAppend,
  },
  data() {
    return {
      imageUrl: "",
      lat: 29.591768,
      lng: 52.583698,
      zoom: 17,
      center: latLng(29.591768, 52.583698),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 17,
      currentCenter: latLng(29.591768, 52.583698),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },

      showMap: true,

      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      perPage: 10,
      page: 1,
      provinces: [],
      cities: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "نام",
          slug: "name",
          sortable: true,
        },
        {
          title: "نام استان",
          slug: "province_name",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      provincesCancelToken: undefined,
      cityInput: {
        id: "",
        state_id: "",
        name: "",
        slug: "",
        status: "active",
        primary_city: "inactive",
        description: "",
        order_city: "",
        images: "",
        videos: "",
        podcasts: "",
        faqs: "",
        lat: "",
        lng: "",
        seo_data_title: "",
        seo_data_keywords: "",
        seo_data_description: "",
        seo_data_schema: "",
        banner: null,
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getCities();
    this.getProvinces();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getCities();
    },
    perPage(value) {
      this.page = 1;
      this.getCities();
    },
    page(value) {
      this.getCities();
    },
  },
  methods: {
    inputFile(file) {
      this.cityInput.banner = file;
    },
    searchLatLng() {
      this.center = latLng(this.cityInput.lat, this.cityInput.lng);
    },
    sliderShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;

      console.log("center", center);
      this.cityInput.lat = center.lat;
      this.cityInput.lng = center.lng;
      this.center = latLng(this.cityInput.lat, this.cityInput.lng);
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },

    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getCities();
    },

    async getCities() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        "filter[name]": this.searchInput,
        // 'filter[slug]':this.searchInput,
        // per_page: this.perPage,
        page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/cities",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.cities = data.data;
          this.paginationData = data.meta;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showConfirmModal(city) {
      this.$bvModal
        .msgBoxConfirm(`آیا از حذف شهر مطمئن هستید ؟  : ${city.name}.`, {
          title: this.$t("Please Confirm"),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (confirmed) => {
          if (confirmed) {
            let cityIndex = 0;
            this.cities = await this.cities.filter((cityItem, index) => {
              if (cityItem.id == city.id) {
                cityIndex = index;
              }
              return cityItem.id !== city.id;
            });

            const requestData = {
              method: "delete",
              url: `/admin/cities/${city.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(`شهر: ${city.name} با موفقیت حذف شد.`, {
                  title: this.$t("Done"),
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.cities.splice(cityIndex, 0, city);
              });
          }
        });
    },
    showFormModal(city) {
      if (city) {
        this.cityInput = {
          id: city.id,
          state_id: city.state.id,
          name: city.name,
          slug: city.slug,
          status: city.status,
          primary_city: city.primary_city,
          description: city.description,
          order_city: city.order_city,
          images: city.images,
          videos: city.videos,
          podcasts: city.podcasts,
          faqs: city.faqs,
          lat: city.lat,
          lng: city.lng,
          seo_data_title: city.seo_data.title,
          seo_data_keywords: city.seo_data.keywords,
          seo_data_description: city.seo_data.description,
          seo_data_schema: city.seo_data.schema,
          banner: city.banner,
        };
        this.$refs.bannerFilePicker.clearFileInput();
        this.searchLatLng();
        this.imageUrl = city.banner.path;
        this.editMode = true;
      } else {
        this.cityInput = {
          id: "",
          state_id: "",
          name: "",
          slug: "",
          status: "active",
          primary_city:"inactive",
          description: "",
          order_city: "",
          images: "",
          videos: "",
          podcasts: "",
          faqs: "",
          lat: "",
          lng: "",
          seo_data_title: "",
          seo_data_keywords: "",
          seo_data_description: "",
          seo_data_schema: "",
          banner: null,
        };
        this.$refs.bannerFilePicker.clearFileInput();
        this.imageUrl = "";
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.cityInput.state_id) {
        errorMessage = "لطفا یک استان را انتخاب کنید";
      }
      if (!errorMessage && !this.cityInput.name) {
        errorMessage = "لطفا نام را وارد کنید";
      }
      if (!errorMessage && this.cityInput.name.length < 2) {
        errorMessage = "نام نمی تواند کمتر از 2 کاراکتر باشد";
      }
      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      let formData = new FormData();
      if (this.cityInput.state_id) {
        formData.append("state_id", this.cityInput.state_id);
      }
      if (this.cityInput.name) {
        formData.append("name", this.cityInput.name);
      }
      if (this.cityInput.slug) {
        formData.append("slug", this.cityInput.slug);
      }
      if (this.cityInput.status) {
        formData.append("status", this.cityInput.status);
      }
      if (this.cityInput.primary_city) {
        formData.append("primary_city", this.cityInput.primary_city);
      }
      if (this.cityInput.description) {
        formData.append("description", this.cityInput.description);
      }
      if (this.cityInput.order_city) {
        formData.append("order_city", this.cityInput.order_city);
      }
      if (this.cityInput.images) {
        formData.append("images", this.cityInput.images);
      }
      if (this.cityInput.videos) {
        formData.append("videos", this.cityInput.videos);
      }
      if (this.cityInput.podcasts) {
        formData.append("podcasts", this.cityInput.podcasts);
      }
      if (this.cityInput.faqs) {
        formData.append("faqs", this.cityInput.faqs);
      }
      if (this.cityInput.lat) {
        formData.append("lat", this.cityInput.lat);
      }
      if (this.cityInput.lng) {
        formData.append("lng", this.cityInput.lng);
      }
      if (this.cityInput.seo_data_title) {
        formData.append("seo_data_title", this.cityInput.seo_data_title);
      }
      if (this.cityInput.seo_data_keywords) {
        formData.append("seo_data_keywords", this.cityInput.seo_data_keywords);
      }
      if (this.cityInput.seo_data_description) {
        formData.append(
          "seo_data_description",
          this.cityInput.seo_data_description
        );
      }
      if (this.cityInput.seo_data_schema) {
        formData.append("seo_data_schema", this.cityInput.seo_data_schema);
      }
      if (this.cityInput.banner) {
        formData.append("banner", this.cityInput.banner);
      }

      console.log( this.cityInput.primary_city);
      const requestData = {
        method: this.editMode ? "post" : "post",
        url: this.editMode
          ? `/admin/cities/update/${this.cityInput.id}`
          : `/admin/cities`,
        data: formData,
      };

      this.$http(requestData)
        .then((response) => {
          let message = "شهر با موفقیت اضافه شد";
          if (this.editMode) message = "شهر با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (!this.editMode && this.provinces && this.provinces.length > 0) {
            this.$refs.citiesPaginationComponent.clear();
            // this.$refs.citiesSearchComponent.clear();
            this.$refs.citiesTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getCities();
        })
        .catch((err) => {
          let message = "خطا در ایجاد شهر";
          if (this.editMode) message = "خطا در ویرایش شهر";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
    },
    getProvinces(search, loading) {
      if (typeof this.provincesCancelToken != typeof undefined) {
        this.provincesCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.provincesCancelToken = this.$http.CancelToken.source();

      let data = {
        //paginate: true,
        //  this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/states",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.provinces = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
/* required styles */
@import "@core/scss/vue/libs/vue-select.scss";
[dir="rtl"] .leaflet-pane,
[dir="rtl"] .leaflet-tile,
[dir="rtl"] .leaflet-marker-icon,
[dir="rtl"] .leaflet-marker-shadow,
[dir="rtl"] .leaflet-tile-container,
[dir="rtl"] .leaflet-pane > svg,
[dir="rtl"] .leaflet-pane > canvas,
[dir="rtl"] .leaflet-zoom-box,
[dir="rtl"] .leaflet-image-layer,
[dir="rtl"] .leaflet-layer {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  direction: ltr !important;
}

[dir="rtl"] .leaflet-container {
  overflow: hidden;
  direction: ltr !important;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}
.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  /* behavior: url(#default#VML); */
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-top {
  top: 0;
}
.leaflet-right {
  left: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: 0;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}
.leaflet-container a {
  color: #0078a8;
}
.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}
.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a:hover {
  background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  /* background-image: url(images/layers.png); */
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  /* background-image: url(images/layers-2x.png); */
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
/* .leaflet-default-icon-path {
	background-image: url(images/marker-icon.png);
	} */

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}
.leaflet-popup-content p {
  margin: 18px 0;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;

  margin: -10px auto 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;

  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}
</style>